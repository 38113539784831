import React, { useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  Button,
  Features,
  FeaturesProps,
  FormState,
  Spacing,
  Typography,
} from "@welldigital/components";
import { Box, Link } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { DataForwarder } from "./Modal/FluAddOnModal";
import { formatPrice } from "../../../../utils/price.utils";
import useFormContext from "../../../../utils/useFormContext";
import { useCartStore } from "../../../../stores/cart/cart.store";
import { PaymentTypes } from "../../../../stores/appointment/appointment.types";
import { checkIfAboveExemptAge } from "../../CustomerDetailsPage.utils";
import { Service } from "../../../../stores/service/service.types";
import { useServiceStore } from "../../../../stores/service/service.store";
import { ServiceIds } from "../../../../stores/service/service.constants";


export type FluAddonProps = {
  openModal: () => void;
  dataForwarder: React.MutableRefObject<DataForwarder>;
  onAddonConfirmed: (addon: Service) => void;
};

const FluAddon: React.FC<FluAddonProps> = ({
  openModal,
  dataForwarder,
  onAddonConfirmed,
}) => {
  const showCartFlag = useCartStore((state) => state.showCartFlag);
  const services = useServiceStore((state) => state.services);
  const Flu = useServiceStore((state) => state.serviceMap[ServiceIds.Flu]);
  const FluPPV = useServiceStore(
    (state) => state.serviceMap[ServiceIds.FluPPV]
  );
  const FluPrevenar13 = useServiceStore(
    (state) => state.serviceMap[ServiceIds.FluPrevenar13]
  );
  const { form, state } = useFormContext();
  const serviceOverride = form.watch("serviceOverride");
  const birthDate = form.watch("details.birthDate");
  const paymentType = form.watch("paymentType");
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const addonAdded = !!serviceOverride;
  const service = addonAdded
    ? (services.find(({ id }) => id === serviceOverride.id) as Service)
    : Flu;

  const isAboveVacExemptAge = useMemo(
    () => !!birthDate && checkIfAboveExemptAge(birthDate),
    [birthDate]
  );

  useEffect(() => {
    dataForwarder.current.setFormData = (serviceOverride, surgeryDetails) => {
      onAddonConfirmed(serviceOverride);
      form.setValue("serviceOverride", serviceOverride);
      form.setValue("surgeryDetails", surgeryDetails);
      showCartFlag();
    };
  }, [dataForwarder, form, showCartFlag, onAddonConfirmed]);

  useEffect(() => {
    if (typeof dataForwarder.current.setBirthDate === "function") {
      dataForwarder.current.setBirthDate(birthDate);
    }
  }, [dataForwarder, dataForwarder.current.setBirthDate, birthDate]);

  const features: FeaturesProps["items"] = useMemo(
    () => [
      {
        icon: Check,
        message: "It only adds five minutes to your appointment time",
      },
      {
        icon: Check,
        message: "You’ll be vaccinated against both pneumonia and flu",
      },
      { icon: Check, message: "Suitable for people aged two and over*" },
    ],
    []
  );

  const removeAddon = useCallback(() => {
    form.setValue("serviceOverride", "");
    form.setValue("surgeryDetails", "");
    showCartFlag();
  }, [form, showCartFlag]);

  const alertMessage = useMemo(() => {
    let serviceName = service.name;
    let fee = service.fee;
    if (paymentType === PaymentTypes.exempt) {
      serviceName += " - NHS";
      fee = service.fee - Flu.fee;
    } else if (paymentType === PaymentTypes.voucher) {
      serviceName += " - Voucher";
      fee = service.fee - Flu.fee;
    }
    return `${serviceName} - ${formatPrice(fee)}`;
  }, [service.name, service.fee, paymentType, Flu.fee]);

  if (addonAdded) {
    return (
      <Box mt={2}>
        <Typography variant={"h6"} spacingAfter={3}>
          You have added a pneumonia vaccination to this appointment
        </Typography>

        <Alert
          icon={Check}
          message={alertMessage}
          size={"small"}
          type={"success"}
          onClose={removeAddon}
          closeLabel={"Remove"}
          disabled={state === FormState.submitting}
        />
        <Spacing spacing={2} />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Typography variant={"h6"} spacingAfter={3}>
        Would you like to book a pneumonia vaccination at the same time?
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        You can now get a pneumonia vaccination at the same time as a flu
        vaccination.{" "}

        {isAboveVacExemptAge

          ? `Price from ${formatPrice(FluPPV.fee - Flu.fee)}*.`
          : `This service costs ${formatPrice(FluPrevenar13.fee - Flu.fee)}*.`}
      </Typography>

      <Box mb={3}>
        <Features items={features} fullWidth size={"large"} />
      </Box>

      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        * Find out more about the pneumonia vaccination on{" "}
        <Link
          href={"https://www.well.co.uk/vaccinations/pneumonia"}
          target={"_blank"}
          color={"primary"}
        >
          our website
        </Link>
        .
      </Typography>

      <Button
        color={"default"}
        variant={"contained"}
        fullWidth
        children={"Add pneumonia vaccine to your booking"}
        onClick={openModal}
        disabled={state === FormState.submitting}
      />

      <Spacing spacing={2} />
    </Box>
  );
};

export default FluAddon;
